import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  energyValueForMonth: [],
}

export const energySlice = createSlice({
  name: 'energy',
  initialState,
  reducers: {
    saveenergyformonth: (state,action) => {
      // state.energyValueForMonth=action.payload
      state.energyValueForMonth=Array.from({length: 30}, () => Math.floor(Math.random() * 30));
    },
  },
})

// Action creators are generated for each case reducer function
export const { saveenergyformonth } = energySlice.actions

export default energySlice.reducer
