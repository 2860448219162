import React from "react";
import { useSelector } from "react-redux";

export default function EnergyDataTable({ isAIMood }) {
  const saveenergyformonth=useSelector((state)=>state.energy.energyValueForMonth)
  const tableData2 = [
    {
      ID: "1",
      Type: "4500",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "2",
      Type: "4600",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "2%",
      aiStatus: "more",
    },
    {
      ID: "3",
      Type: "4700",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "11%",
      aiStatus: "less",
    },
    {
      ID: "4",
      Type: "4550",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "7%",
      aiStatus: "less",
    },
    {
      ID: "5",
      Type: "3450",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "0.8%",
      aiStatus: "less",
    },
    {
      ID: "6",
      Type: "2000",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "9%",
      aiStatus: "more",
    },
    {
      ID: "7",
      Type: "3456",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "8",
      Type: "4567",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "9",
      Type: "2345",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "10",
      Type: "4325",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "11",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "12",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "13",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "14",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "15",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "16",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "17",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "18",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "19",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "20",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "21",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "22",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "23",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "24",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "25",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "26",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "27",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "28",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "29",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
    {
      ID: "30",
      Type: "6754",
      Location: "- 180(kWh)",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "8%",
      aiStatus: "less",
    },
  ];
  const tableData = [
    {
      ID: "Jan",
      Type: "4560",
      Location: 80,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 8,
      aiStatus: "less",
    },
    {
      ID: "Feb",
      Type: "4736",
      Location: 95,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: "2",
      aiStatus: "more",
    },
    {
      ID: "Mar",
      Type: "4876",
      Location: 108,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 11,
      aiStatus: "less",
    },
    {
      ID: "Apr",
      Type: "4208",
      Location: 113,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 7,
      aiStatus: "less",
    },
    {
      ID: "May",
      Type: "4512",
      Location: 124,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 7,
      aiStatus: "less",
    },
    {
      ID: "Jun",
      Type: "4511",
      Location: 130,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 9,
      aiStatus: "more",
    },
    {
      ID: "Jul",
      Type: "4390",
      Location: 141,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 8,
      aiStatus: "less",
    },
    {
      ID: "Aug",
      Type: "4042",
      Location: 153,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 8,
      aiStatus: "less",
    },
    {
      ID: "Sep",
      Type: "4218",
      Location: 160,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 5,
      aiStatus: "less",
    },
    {
      ID: "Oct",
      Type: "4178",
      Location: 90,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 10,
      aiStatus: "less",
    },
    {
      ID: "Nov",
      Type: "4204",
      Location: 48,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 12,
      aiStatus: "less",
    },
    {
      ID: "Dec",
      Type: "4111",
      Location: 30,
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
      aiValue: 6,
      aiStatus: "less",
    }
  ];
  saveenergyformonth?.map((item,index)=>{
    // tableData[index].Type=item?.toFixed(2)
    if(index<12){
      tableData[index].Type=item?.toFixed(2)
    }
  })

  console.log("tableData",tableData)
  return (
    <div>
      <div
        className="bg-[#FFFFFF0D] border border-[#FFFFFF0D] rounded-lg"
        style={{ boxShadow: "0px 1px 3px 0px #000000" }}
      >
        <div className="px-[20px] pt-[20px] flex justify-between items-center">
          <p className="sm:text-[18px] text-[12px] font-semibold">
            Energy Data Charts
          </p>
          <select className="border border-[#FFFFFF08] custom-select bg-[#FFFFFF0D] w-[102px] h-[34px] text-[13px] rounded-[8px]">
            <option>Monthly</option>
            <option>Yearly</option>
            <option>Daily</option>
          </select>
        </div>

        <div className="relative overflow-x-auto sm:rounded-lg mt-[23px] h-[250px] my-scrollar overflow-y-auto">
          <table className="w-full text-sm text-left ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th colSpan="1" scope="col" className="px-[20px] font-normal py-3 text-[#9BB9A9]">
                  Month
                </th>
                <th colSpan="1" scope="col" align="center" className="px-[20px] font-normal py-3 text-[#9BB9A9]">
                    Energy (kWh)
                </th>
                <th colSpan="1" scope="col" align="center" className="px-[20px] font-normal py-3 text-[#9BB9A9]">
                    Changes From Last Year
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index} className={`${index % 2 === 1 ? "bg-[#FFFFFF00] " : "bg-[#FFFFFF0D]"}`}>
                  <th className="px-[20px]   py-2 font-medium text-white whitespace-nowrap ">
                    {item?.ID}
                  </th>
                  <td className="py-2 text-center" align="center">{item?.Type}</td>
                  <td className="py-2 text-center flex justify-center" align="center">
                    <div className="flex items-center gap-[4px]">
                      + {isAIMood?(item?.aiStatus === "less"?(item?.Location - (item?.aiValue * item?.Location * 0.001 )): (item?.Location + (item?.aiValue * item?.Location * 0.001 ))):item?.Location}
                      <small>KWh</small>
                      {isAIMood && (item?.aiStatus === "less"?<img src="/less.svg" alt="" />:<img style={{rotate: '180deg'}} src="/more.svg" alt="" />)}
                      {isAIMood && (
                        <span
                          className={`${
                            item?.aiStatus === "less"
                              ? "text-[#99CC33]"
                              : "text-[#B94D4D]"
                          }`}
                        >
                          {item?.aiValue}
                        </span>
                      )}
                      {isAIMood && (
                        <span
                          className={`${
                            item?.aiStatus === "less"
                              ? "text-[#99CC33]"
                              : "text-[#B94D4D]"
                          }`}
                        >
                          {item?.aiStatus}
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
